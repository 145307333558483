/**
 * LM-LIFF-03 綁定流程-OTP認證 https://lmliff.cht.com.tw/sign-up/validate
 */
import { useMemo } from 'react';

import ModuleValidation from '@/modules/ModuleValidation';
import { PropTypes } from '@/utils/propTypes';

const SignUpValidatePage = ({ location }) => {
  const { state } = useMemo(
    () =>
      (typeof window !== 'undefined' && location) ?? {
        state: {},
      },

    [location]
  );

  return (
    <ModuleValidation currentStep={1} linkFormData={state || { mobile: '' }} />
  );
};

SignUpValidatePage.propTypes = {
  location: PropTypes.object,
};
export default SignUpValidatePage;
